'use client';

import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './nav-item.module.scss';
import { ChevronDown } from '@/shared/ui/icons/ChevronDown';
import { Typography } from '@/shared/ui/typography';

interface INavItemProps {
  title: string;
  children?: ReactElement[]
  href?: string
}

export const NavItem: FC<INavItemProps> = ({ title, children, href }) => {
  const columns = (children?.length ?? 1) > 4 ? styles.three : styles.two;

  return (
        <div className={classNames(styles.container)}>
            {!children && href ? (
                <Link className={classNames(styles.button, styles.children)} href={href}>
                    <Typography as="span" variant="caps">{title}</Typography>
                </Link>
            ) : (
                <>
                    <button className={classNames(styles.button, children && styles.children)}>
                        <Typography as="span" variant="caps">{title}</Typography>
                        {children && (
                            <span className={styles.chevron}>
                        <ChevronDown/>
                    </span>
                        )}
                    </button>
                    {children && (
                        <div className={classNames(styles.dropdown, columns)}>
                            {children}
                        </div>
                    )}
                </>
            )}
        </div>
  );
};