'use client';

import { FC, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';
import styles from './accordion-link.module.scss';
import { AccordionLinkProps } from './interfaces';
import { ChevronDown } from '@/shared/ui/icons/ChevronDown';
import './accordion-link.module.scss';

const iconVariants = {
  open: {
    rotate: '180deg',
    y: '-50%',
  },
  closed: {
    rotate: 0,
    y: '-50%',
  },
};

const dropdownVariants = {
  open: {
    height: 'auto',
  },
  closed: {
    height: 0,
  },
};

export const AccordionLink: FC<AccordionLinkProps> = ({ title, href, children, background = 'gold', onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const animateIcon = isOpen ? 'open' : 'closed';

  const onOpenHandler = () => setIsOpen(!isOpen);

  return (
        <div className={styles.container}>
            {href && (
                <Link onClick={onClick} className={styles.link} href={href}>
                    {title}
                </Link>
            )}
            {!href && (
                <div className={styles.header} onClick={onOpenHandler}>
                    {title}
                    <motion.span className={styles.icon} initial="closed" variants={iconVariants} animate={animateIcon}>
                        <ChevronDown />
                    </motion.span>
                </div>
            )}
            {!href && (
                <motion.div className={classNames(styles.dropdown, background === 'gold' && styles.gold)} initial="closed" variants={dropdownVariants} animate={animateIcon}>
                  <div className={styles.items}>
                    {children}
                  </div>
                </motion.div>
            )}
        </div>
  );
};