'use client';

import { useGlobalStore } from '@/features/global/hooks';
import { Footer } from '@/widgets/footer';

export const GlobalFooter = () => {
  const { header, footer } = useGlobalStore();
  const { description, dzen, youtube, vk, telegram } = footer;
  const { menu } = header;
  const itemsWithChildren = menu?.filter((item) => 'items' in item) ?? [];
  const singleItems = menu?.filter((item) => !('items' in item)) ?? [];

  return <Footer navigationChildren={itemsWithChildren} navigationSingle={singleItems} dzen={dzen} description={description} vk={vk} youtube={youtube} telegram={telegram} />;
};