'use client';

import { createContext, FC, ReactNode, useContext } from 'react';
import { IGlobalData } from '@/shared/interfaces/global';

const GlobalContext = createContext<IGlobalData>({
  header: {},
  footer: {},
  contacts: {},
});

export const GlobalProvider: FC<{ value: any, children: ReactNode }> = ({ value, children }) => {

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalProvider = () => useContext(GlobalContext);
