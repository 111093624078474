'use client';

import { FC } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';
import styles from './footer.module.scss';
import { IFooter } from '@/shared/interfaces/footer';
import { AccordionLink } from '@/shared/ui/accordion-link';
import { AppearAnimation } from '@/shared/ui/appear-animation';
import { LsrLogo } from '@/shared/ui/lsr-logo';
import { Typography } from '@/shared/ui/typography';
import { IFooterProps } from '@/widgets/footer/interfaces';

const year = new Date().getFullYear();

export const Footer: FC<Partial<IFooter & IFooterProps>> = ({ dzen, vk, youtube, telegram, description, navigationChildren, navigationSingle }) => {

  return (
        <AppearAnimation>
            {(props) => (
                <motion.footer className={classNames('container', styles.border)} {...props}>
                    <div className={classNames(styles.container)}>
                        <div className={styles.nav}>
                            {navigationChildren?.map((item, index) => (
                                <div key={index} className={styles.column}>
                                    <Typography as="span" variant="caps" className={styles.golden}>{item.title}</Typography>
                                    <nav className={styles.links}>
                                        {item?.items?.map((child, childIndex) => (
                                            <Link key={childIndex} className={styles.link} href={child.link}>{child.title}</Link>
                                        ))}
                                    </nav>
                                </div>
                            ))}
                            <div className={styles.column}>
                                <nav className={styles.links}>
                                    {navigationSingle?.map((item, index) => (
                                        <Link key={index} className={classNames(styles.bold)} href={item.link}>{item.title}</Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className={styles.mobile}>
                            {navigationChildren?.map((item, index) => (
                                <AccordionLink
                                    key={index}
                                    title={<Typography as="span" variant="caps" className={styles.golden}>{item.title}</Typography>}>
                                    {item?.items?.map((childItem, childIndex) => (
                                        <Link key={childIndex} className={styles.link} href={childItem.link}>{childItem.title}</Link>
                                    ))}
                                </AccordionLink>
                            ))}
                            {navigationSingle?.map((item, index) => (
                                <AccordionLink key={index} href={item.link} title={<Typography as="span" variant="caps"
                                                                           className={styles.golden}>{item.title}</Typography>}/>
                            ))}
                        </div>
                        <div className={styles.about}>
                            <div className={styles.small}>
                                {description}
                            </div>
                            <ul className={styles.socials}>
                                <li>
                                    <Link className={styles.vk} href={vk?.vkLink ?? ''} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                                             viewBox="0 0 21 21"
                                             fill="currentColor">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M1.47619 1.47618C0 2.95237 0 5.32825 0 10.08V10.92C0 15.6718 0 18.0476 1.47619 19.5238C2.95237 21 5.32828 21 10.0801 21H10.9201C15.6718 21 18.0477 21 19.5239 19.5238C21 18.0476 21 15.6718 21 10.92V10.08C21 5.32825 21 2.95237 19.5239 1.47618C18.0477 0 15.6718 0 10.9201 0H10.0801C5.32828 0 2.95237 0 1.47619 1.47618ZM3.54379 6.38755C3.65755 11.8475 6.38754 15.1288 11.1738 15.1288H11.4451V12.005C13.2039 12.18 14.5338 13.4663 15.0675 15.1288H17.5526C16.8701 12.6438 15.0763 11.27 13.9563 10.745C15.0763 10.0975 16.6513 8.52254 17.0275 6.38755H14.77C14.2799 8.12005 12.8276 9.69503 11.4451 9.84377V6.38755H9.18753V12.4425C7.78754 12.0925 6.02006 10.395 5.94131 6.38755H3.54379Z"
                                                  />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={styles.youtube} href={youtube?.youtubeLink ?? ''} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             viewBox="0 0 30 30" fill="currentColor">
                                            <path
                                                d="M12.5 18.75L18.9875 15L12.5 11.25V18.75ZM26.95 8.9625C27.1125 9.55 27.225 10.3375 27.3 11.3375C27.3875 12.3375 27.425 13.2 27.425 13.95L27.5 15C27.5 17.7375 27.3 19.75 26.95 21.0375C26.6375 22.1625 25.9125 22.8875 24.7875 23.2C24.2 23.3625 23.125 23.475 21.475 23.55C19.85 23.6375 18.3625 23.675 16.9875 23.675L15 23.75C9.7625 23.75 6.5 23.55 5.2125 23.2C4.0875 22.8875 3.3625 22.1625 3.05 21.0375C2.8875 20.45 2.775 19.6625 2.7 18.6625C2.6125 17.6625 2.575 16.8 2.575 16.05L2.5 15C2.5 12.2625 2.7 10.25 3.05 8.9625C3.3625 7.8375 4.0875 7.1125 5.2125 6.8C5.8 6.6375 6.875 6.525 8.525 6.45C10.15 6.3625 11.6375 6.325 13.0125 6.325L15 6.25C20.2375 6.25 23.5 6.45 24.7875 6.8C25.9125 7.1125 26.6375 7.8375 26.95 8.9625Z"
                                                />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={styles.telegram} href={telegram?.telegramLink ?? ''} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27"
                                             viewBox="0 0 27 27" fill="currentColor">
                                            <path
                                                d="M23.2477 4.18081L3.30143 11.8724C1.94018 12.4192 1.94806 13.1786 3.05168 13.5172L8.17268 15.1147L20.0212 7.63906C20.5814 7.29819 21.0933 7.48156 20.6726 7.85506L11.0729 16.5187H11.0707L11.0729 16.5198L10.7197 21.7983C11.2372 21.7983 11.4656 21.5609 11.7558 21.2808L14.2432 18.8621L19.4171 22.6837C20.3711 23.2091 21.0562 22.9391 21.2936 21.8006L24.6899 5.79406C25.0376 4.40019 24.1578 3.76906 23.2477 4.18081Z"
                                                />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={styles.dzen} href={dzen?.dzenLink ?? ''} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="currentColor">
                                            <path
                                                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                                />
                                            <path
                                                d="M0 11.6666V12.324C0 12.324 6.67752 12.0079 9.33336 14.6666C11.9886 17.3248 11.6666 24 11.6666 24H12.3334C12.3334 24 12.01 17.3233 14.6666 14.6666C17.3233 12.01 24 12.3332 24 12.3332V11.6759C24 11.6759 17.3225 11.992 14.6666 9.33324C12.0114 6.67524 12.3334 0 12.3334 0H11.6666C11.6666 0 11.99 6.67668 9.33336 9.33324C6.67668 11.9899 0 11.6666 0 11.6666Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.logo}>
                                <LsrLogo />
                                <Typography as="span" variant="small">© Группа ЛСР, {year}</Typography>
                            </div>
                            <div>
                                <Link className={styles.pinkpixel} href="//pinkpixel.ru/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="115" height="15" viewBox="0 0 115 15"
                                         fill="currentColor">
                                        <path
                                            d="M59.0273 10.6345V3.52956L61.8309 0C62.9202 0 66.3959 0.214868 67.2129 0.644604C68.0298 1.07434 68.6653 1.67836 69.1191 2.45666C69.573 3.23496 69.8 4.14456 69.8 5.18548C69.8 6.23595 69.5659 7.14555 69.0976 7.9143C68.6342 8.68305 67.9821 9.27513 67.1412 9.69055C66.3051 10.106 65.3281 10.3137 64.2101 10.3137H60.4262V7.21956H63.4075C63.8757 7.21956 64.2746 7.13839 64.6043 6.97605C64.9387 6.80893 65.1943 6.57257 65.3711 6.26698C65.5526 5.96139 65.6434 5.60089 65.6434 5.18548C65.6434 4.76529 65.5526 4.40718 65.3711 4.11114C65.1943 3.81033 64.9387 3.58113 64.6043 3.42356C64.2746 3.26122 63.8757 3.18005 63.4075 3.18005H62.0029V14.6683L59.0273 10.6345Z"
                                            />
                                        <path d="M75.2465 0V14.6683H71.2619V0H75.2465Z"/>
                                        <path
                                            d="M81.406 0L83.814 4.26871H83.9286L86.3652 0H90.8084L86.4226 7.33416L90.9804 14.6683H86.4226L83.9286 10.3137H83.814L81.32 14.6683H76.7908L81.2914 7.33416L76.9342 0H81.406Z"
                                            />
                                        <path
                                            d="M92.5266 14.6683V0H103.104V3.2087H96.5111V5.72981H102.56V8.93851H96.5111V11.4596H103.076V14.6683H92.5266Z"
                                            />
                                        <path d="M105.08 14.6683V0H109.065V11.4596H114.999V14.6683H105.08Z"
                                              />
                                        <path
                                            d="M0 14.6683V0H6.33515C7.42445 0 8.37759 0.214868 9.19457 0.644604C10.0115 1.07434 10.647 1.67836 11.1008 2.45666C11.5547 3.23496 11.7817 4.14456 11.7817 5.18548C11.7817 6.23595 11.5476 7.14555 11.0793 7.9143C10.6159 8.68305 9.96377 9.27513 9.1229 9.69055C8.28681 10.106 7.30979 10.3137 6.19182 10.3137H2.40793V7.21956H5.38918C5.85739 7.21956 6.25632 7.13839 6.58598 6.97605C6.92041 6.80893 7.17601 6.57257 7.35279 6.26698C7.53434 5.96139 7.62511 5.60089 7.62511 5.18548C7.62511 4.76529 7.53434 4.40718 7.35279 4.11114C7.17601 3.81033 6.92041 3.58113 6.58598 3.42356C6.25632 3.26122 5.85739 3.18005 5.38918 3.18005H3.98455V14.6683H0Z"
                                            />
                                        <path d="M14.1896 14.6683V0H28.8665V14.6683H14.1896Z" />
                                        <path
                                            d="M44.4087 0V14.6683H41.0835L35.7803 6.96172H35.6943V14.6683H31.7098V0H35.0924L40.3095 7.67795H40.4242V0H44.4087Z"
                                            />
                                        <path
                                            d="M46.2935 14.6683V0H50.2781V5.93036H50.4787L54.8933 0H59.5371L54.578 6.53199L59.6518 14.6683H54.8933L51.5967 9.1677L50.2781 10.8866V14.6683H46.2935Z"
                                            />
                                    </svg>
                                    <span>Разработка и продвижение</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </motion.footer>
            )}
        </AppearAnimation>
  );
};