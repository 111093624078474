import { FC } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './button.module.scss';
import { ButtonProps } from './interfaces';
import { Typography } from '@/shared/ui/typography';

export const Button: FC<ButtonProps> = ({ children, href, disabled, onClick, type, icon, className, isActive, activeClassName, htmlType = 'button', target }) => {


  return (
        <div
            className={classNames(styles.container, disabled && styles.disabled, type === 'secondary' && styles.secondary, type === 'thin' && `${styles.thin}`, className, isActive && activeClassName)}>
            {href ? (
                <Link href={href} target={target}
                      onClick={onClick}
                      className={classNames(disabled && styles.disabled, type === 'secondary' && styles.secondary, type === 'thin' && `${styles.thin}`)}>
                    <Typography as="span" variant={type === 'thin' ? 'h4' : 'caps'}>{children}</Typography>
                    {icon && (
                        <span className={styles.icon}>{icon}</span>
                    )}
                    {type != 'secondary' && (
                        <span className={styles.gradient}/>
                    )}
                </Link>
            ) : (
                <button
                    type={htmlType}
                    className={classNames(type === 'secondary' && styles.secondary, type === 'thin' && `${styles.thin}`)}
                    disabled={disabled} onClick={onClick}>
                    <Typography as="span" variant={type === 'thin' ? 'h4' : 'caps'}>{children}</Typography>
                    {icon && (
                        <span className={styles.icon}>{icon}</span>
                    )}
                    {type != 'secondary' && (
                        <span className={styles.gradient}/>
                    )}
                </button>
            )
            }
        </div>
  );
};