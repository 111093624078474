'use client';

import { ReactElement, Ref, useRef } from 'react';
import { useInView, Variants } from 'framer-motion';

const variants = {
  hidden: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 1,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      when: 'beforeChildren',
    },
  },
};

interface IAppearAnimation {
  animate: string
  transition: {
    [key: string]: number[]
  },
  variants: Variants
  initial: string
  ref: Ref<any>
}

export const AppearAnimation = ({ children }: { children(props: IAppearAnimation): ReactElement | ReactElement[] }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef, { once: true });
  const animate = isInView ? 'visible' : 'hidden';
  const transition = { ease: [57, .21, .69, 1.25] };
  const initial = 'hidden';

  return children({ animate, variants, initial, transition, ref: containerRef });
};